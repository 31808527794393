.loader {
  font-size: 200px;
  color:#FFF;
  background-color: #000;
  position:fixed;
  width:100%;
  height:100%;
  z-index:1000;
  display:flex;
  align-items: center;
  justify-content: center;
  animation: 5s linear 0s fadein;
  .loader-bar-container {
    width:500px;
    max-width:90%;
    background-color:#FFF2;
    .loader-bar {
      background-color:#FFF;
      height:5px;
    }
  }
}
@keyframes fadein { from { color:#FFF0; } to { color:#FFFF; }  }
