.conversation-player-conversation {
  margin-top:50px;
  .conversation-interlocuteurs {
    display: flex;
    justify-content: center;
    border-top: 2px solid #FFF;
    overflow: hidden;
    .conversation-interlocuteur {
      width:50px;
      height:50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size:25px;
      margin:0 5px;
      position:relative;
      top:-8px;
      background: url('./assets/avatar-full.svg') center -3px no-repeat;
      background-size: contain;
    }
  }
  .conversation-date {
    text-align: center;
    font-size:25px;
    margin:5px 0;
  }
  .conversation-from {
    font-size:20px;
    font-weight:bold;
    margin-top:30px;
  }
  .conversation-message {
    padding:0 80px;
  }
  .conversation-message-content {
    display: flex;
    .conversation-message-bulle {
      padding:10px;
      font-size:22px;
      margin: 5px 0;
      position:relative;
      min-width: 10px;
      min-height:10px;
      .lien {
        cursor:pointer;
        text-decoration: underline;
        text-decoration-thickness: var(--pixelSize);
        font-weight: bold;
      }
      .text {
        position:relative;
        z-index:1;
        white-space: pre-line;
      }
    }
    .conversation-message-failed {
      font-size:0.8em;
      .failed-icon {
        display: inline-block;
        position:relative;
        bottom:-4px;
        height:16px;
        width:16px;
        background-size: contain;
        background-image:url('./assets/close.svg');
      }
    }
    .conversation-message-missed {
      background-color:#3e3e3e;
      border-radius: 4px;
      color:#FFF;
      margin: 5px 0;
      padding:5px;
      line-height:20px;
      .missed-call-icon {
        display: inline-block;
        position:relative;
        bottom:-2px;
        height:16px;
        width:16px;
        background-size: contain;
        background-image:url('./assets/missed-call.svg');
      }
    }
  }
  .conversation-message-right {
    text-align: right;
    .conversation-message-content {
      justify-content: flex-end;
      .conversation-message-bulle .background-0 {
        position:absolute;
        z-index:0;
        top:0;
        left:0;
        width:50%;
        height:50%;
        background: url('./assets/bulle-right.svg') left top no-repeat;
      }
      .conversation-message-bulle .background-1 {
        top:0;
        right:-16px;
        position:absolute;
        z-index:0;
        width:calc(50% + 18px);
        height:50%;
        background: url('./assets/bulle-right.svg') right top no-repeat;
      }
      .conversation-message-bulle .background-2 {
        bottom:0;
        left:0;
        position:absolute;
        z-index:0;
        width:50%;
        height:51%;
        background: url('./assets/bulle-right.svg') left bottom no-repeat;
      }
      .conversation-message-bulle .background-3 {
        bottom:0;
        right:-16px;
        position:absolute;
        z-index:0;
        width:calc(50% + 18px);
        height:51%;
        background: url('./assets/bulle-right.svg') right bottom no-repeat;
      }
    }
  }
  .conversation-message-left {
    text-align: left;
    .conversation-message-content {
      justify-content: flex-start;
      color:#000;
      .conversation-message-bulle .background-0 {
        position:absolute;
        z-index:0;
        top:0;
        left:-16px;
        width:calc(50% + 18px);
        height:50%;
        background: url('./assets/bulle-left.svg') left top no-repeat;
      }
      .conversation-message-bulle .background-1 {
        top:0;
        right:0;
        position:absolute;
        z-index:0;
        width:50%;
        height:50%;
        background: url('./assets/bulle-left.svg') right top no-repeat;
      }
      .conversation-message-bulle .background-2 {
        bottom:0;
        left:-16px;
        width:calc(50% + 18px);
        position:absolute;
        z-index:0;
        height:51%;
        background: url('./assets/bulle-left.svg') left bottom no-repeat;
      }
      .conversation-message-bulle .background-3 {
        bottom:0;
        right:0;
        position:absolute;
        z-index:0;
        width:50%;
        height:51%;
        background: url('./assets/bulle-left.svg') right bottom no-repeat;
      }
    }
  }
}
