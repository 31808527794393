.lettre {
  width:100vw;
  height:100vh;
  background-color: #000;
  cursor:grab;
  &>div, &>div>div {
    width:100%;
    height:100%;
  }
  .lettre-mask {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    pointer-events: none;
    background: radial-gradient(#0000, #000C);
  }
  .lettre-menu-top, .lettre-menu-bottom {
    position:absolute;
    width:100%;
    height:50px;
    left:0;
    opacity:1;
    transition:opacity 0.5s;
    pointer-events: auto;
    display:flex;
    justify-content: center;
    align-items: center;
    .lettre-menu-inner {
      width:100%;
      max-width: 500px;
      height:50px;
      display:flex;
      justify-content: space-evenly;
      align-items: center;
      .menu-item {
        width:50px;
        height:50px;
        opacity:0.5;
        transition:opacity 0.2s;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display:flex;
        justify-content: center;
        align-items: center;
        color:#FFF;
        cursor:pointer;
        &:hover {
          opacity:1;
        }
        &.menu-home {
          background-image: url('../assets/home.svg');
        }
        &.menu-settings {
          background-image: url('../assets/cog.svg');
        }
        &.menu-tapuscrit {
          background-image: url('../assets/tapuscrit.svg');
        }
        &.menu-portrait {
          background-image: url('../assets/bulles.svg');
        }
        &.menu-lettres {
          background-image: url('../assets/docs.svg');
        }
        &.menu-plus {
          background-image: url('../assets/more.svg');
        }
        &.menu-voix {
          background-image: url('../assets/audio-on.svg');
        }
        &.menu-voix-active {
          background-image: url('../assets/audio-off.svg');
        }
      }
    }
  }
  .lettre-menu-top {
    top:30px;
    width:80%;
    left:10%;
    .lettre-menu-inner {
      max-width: 400px;
    }
  }
  .lettre-menu-bottom {
    bottom:30px;
  }
  &.menu-hide {
    .lettre-menu-top, .lettre-menu-bottom {
      opacity:0;
      pointer-events: none;
    }
  }
}
