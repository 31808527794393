.tapuscrit-mask {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color:#000A;
  display:none;
  z-index:1000;
  &.on {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  & .tapuscrit-close-btn {
    width:50px;
    height:50px;
    margin-top:30px;
    opacity:0.5;
    transition:opacity 0.2s;
    &:hover {
      opacity:1;
    }
    background-image: url('../assets/close.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor:pointer;
  }
  & .tapuscrit-inner {
    position: relative;
    width: calc(100% - 80px);
    max-width:500px;
    height: calc(100% - 140px);
    margin-top:30px;
    & .tapuscrit-content {
      & .lettre-surtitre {
        text-align: center;
      }
      & .lettre-interlocuteurs {
        text-align: center;
        margin:5px 0;
        & strong {
          font-size: 1.2em;
        }
      }
      color:#fff;
      & h3 {
        font-size:30px;
        line-height: 1;
        margin:15px 0;
      }
      height:100%;
      overflow-y: auto;
      & .tapuscrit-content-top, & .tapuscrit-content-bottom {
        height:15px;
      }
      & .tapuscrit-text {
        color:#000;
        position:absolute;
        bottom:0;
        left:0;
        width:100%;
        white-space: pre-line;
        background-color: #FFF;
        border-radius: 16px;
        overflow-y:auto;
        height:calc(100% - 80px);
        & .tapuscrit-text-inner {
          padding:15px;
        }
      }
    }
  }
}
