.conversation-player-container {
  position:absolute;
  width:100%;
  height:100%;
  top:0;
  left:0;
  user-select: none;
  background-color:#000;
  background-image: url('../../assets/fond.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  & .conversation-mask {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:radial-gradient(#000c, #000f);;
  }
  & .conversation-player-scroller {
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    color:#fff;
    overflow-y: auto;
    scroll-behavior: smooth;
    & .conversation-player {
      position:absolute;
      top:0;
      left:0;
      transform-origin: left top;
      & .portrait-photo {
        margin-top:30px;
        height:300px;
        width:300px;
        border-radius:150px;
        margin-left:calc( ( var(--width) - 300px ) / 2 );
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        box-shadow: 0 0 20px #000;
      }
      & .portrait-nom {
        margin:15px 0;
        font-size:90px;
        text-align:center;
        font-family:'francis-regular';
      }
      & .portrait-chapeau {
        font-size:25px;
        text-align:center;
        padding: 0 30px;
      }
      & .portrait-separateur {
        height:23px;
        margin:30px auto;
        background-color:#FFF3;
        width: calc( var(--width) - 156px );
      }
    }
    .bottom-item {
      height:100px;
    }
  }
}
