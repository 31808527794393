.lien-mask {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color:#0008;
  display:none;
  z-index:1000;
  &.on {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  & .lien-close-btn {
    width:50px;
    height:50px;
    margin-top:30px;
    opacity:0.5;
    transition:opacity 0.2s;
    &:hover {
      opacity:1;
    }
    background-image: url('./close.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor:pointer;
  }
  & .lien-inner {
    position: relative;
    width: calc(100% - 80px);
    max-width:500px;
    height: calc(100% - 150px);
    margin-top:40px;
    background-color: #FFF;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 0 20px #000;
    & .lien-content {
      position: relative;
      & h3 {
        font-size:30px;
        line-height: 1;
        margin:15px 0;
      }
      height:100%;
      overflow-y: auto;
      & .lien-content-inner {
        padding:0 15px 30px 15px;
        white-space: pre-line;
      }
    }
    & .lien-images {
      position:absolute;
      width:100%;
      height:40%;
      max-height: 300px;
      background-color: #000;
      left:0;
      bottom:0;
      box-shadow: 0 -15px 15px #FFF;
      & .swiper {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        & .swiper-pagination-bullet {
          background-color: #FFF;
          box-shadow: 0 0 20px #000;
          opacity: 0.5;
          &.swiper-pagination-bullet-active {
            opacity: 1;
          }
        }
      }
      & img {
        width:100%;
        height:100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  &.has-images .lien-inner {
    & .lien-content {
      height:60%;
      min-height: calc(100% - 300px);
    }
  }
}
